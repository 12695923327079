import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { mapProps } from "recompose"
import PostCard from "../components/post-card"
import "./styles.css"

let IndexPage = ({ posts }) => {

  return (
    <Layout>
      <Helmet title="Swop4a.ru" />

      <p className="block-title">Все посты</p>
      {
        posts.map(post => {
          return <PostCard post={post} key={post.link} />
        })
      }
    </Layout>
  )
}

export default mapProps(({ data: { allMarkdownRemark: { edges } } }) => ({
  posts: edges.map(edge => ({
    ...edge.node.frontmatter,
    excerpt: edge.node.excerpt,
    link: edge.node.fields.slug
  }))
}))(IndexPage)

export let pageQuery = graphql`
  {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            postImage
            title
          }
          fields {
            slug
          }
          excerpt(pruneLength: 100)
        }
      }
    }
  }
`