import React from "react"
import { Link } from "gatsby"
import "./Card.css"

const PostCard = ({ post }) => {
  return (
    <Link to={post.link} className="not-active">
      <div className="card card-grid">

        <div className="card-grid-image-col"
             style={{ backgroundImage: `url(${post.postImage})` }} />

        <div className="card-grid-content-col">
          <p className="card-title">{post.title}</p>
          <p className="card-description">Ну какой нибудь подзаголовок чтобы как
            нибудь заполнить компонент и чтобы было на пару строчек</p>
        </div>

        <div className="card-grid-parameters-col">
          <p className="card-parameter">20 мая 2020 :: 110650 просмотров :: 6340
            слов</p>
          {/*<span>*/}
          {/*  <img src="/view.svg" alt="logo" className="header-image" />*/}
          {/*  110650*/}
          {/*</span>*/}
          {/*<span>*/}
          {/*  <img src="/font.svg" alt="logo" className="header-image" />*/}
          {/*  6340*/}
          {/*</span>*/}
        </div>
      </div>
    </Link>
  )
}

export default PostCard